import '../inits/bootstrap.js';
import Vue from 'vue';
import vueConf from '../inits/vue-desktop.js';
import desktopLogin from '../views/login/login-desktop.vue';

new Vue({
    el: '.sitecontent',
    components: {
        ...vueConf.components,
        desktopLogin,
    },
    store: vueConf.store,
});
