<template>
    <div class="login-desktop">
        <div class="form-container">
            <div class="darkened">
                <div class="heading">
                    <h1>
                        {{ trans('login.Logg inn') }}<span>{{ trans('login.Kvalitetssystem') }}</span>
                    </h1>
                </div>
                <div class="form">
                    <input
                        v-model="form.username"
                        type="text"
                        :placeholder="trans('homepage.Brukernavn')"
                        @keyup.enter="doLogin"
                    >
                    <input
                        v-model="form.password"
                        type="password"
                        :placeholder="trans('homepage.Passord')"
                        @keyup.enter="doLogin"
                    >
                    <div class="twofa">
                        <input
                            v-if="show2fa"
                            v-model="form.twofactor"
                            type="text"
                            :placeholder="trans('login.Skriv inn tofaktorautentiseringskode')"
                            @keyup.enter="doLogin"
                        >
                        <span v-if="sms && show2fa && timeout !== 0 && !expired">
                            {{ trans('2fa.Koden utløper om') }} {{ remainingTokenTime }} {{ trans('2fa.sekunder') }}
                        </span>
                        <span v-if="sms && show2fa && expired">
                            {{ trans('2fa.Din kode er utløpt') }}
                            {{ trans('2fa.Trykk "logg inn" for å få tilsent en ny kode') }}
                        </span>
                    </div>
                    <div class="bottom">
                        <div class="remember">
                            <label>
                                <input v-model="form.remember" type="checkbox">
                                {{ trans('homepage.Husk meg') }}
                            </label>
                        </div>
                        <button
                            type="button"
                            :disabled="isLoading"
                            :class="{ clickable: !isLoading }"
                            @click="doLogin"
                        >
                            <template v-if="isLoading">
                                {{ trans('login.Laster') }}...
                            </template>
                            <template v-else>
                                {{ trans('login.Logg inn') }}
                            </template>
                        </button>
                    </div>
                </div>
                <div class="errors">
                    <div v-for="(error, index) in messages" :key="index">
                        {{ error }}
                    </div>
                </div>
                <a class="forgot-password" @click="openForgotPasswordPrompt()">
                    {{ trans('homepage.Har du glemt passordet?') }}
                </a>
                <a v-if="show2fa" @click="openForgot2FAPrompt()">{{ trans('login.Har du glemt din tofaktorautentisering?') }}</a>
            </div>
        </div>
        <kk-modal v-if="forgotPassword.showingPrompt" @cancel="closeForgotPasswordPrompt()" @ok="doForgotPassword()">
            <template #title>
                {{ trans('login.Nullstill passord') }}
            </template>
            <template #content>
                <div class="body">
                    <div class="bold textCenter">
                        {{ trans('login.Har du glemt passordet ditt?') }}
                    </div>
                    <div>
                        1. {{ trans('login.For å nullstille passordet må du fylle ut e-postadressen som tilhører brukerkontoen din') }}
                    </div>
                    <div>
                        2. {{ trans('login.Du får deretter tilsent en e-post med en link som nullstiller passordet og lar det velge et nytt passord') }}
                    </div>
                    <input
                        v-model="forgotPassword.email"
                        type="email"
                        name="email"
                        :placeholder="trans('homepage.Din e-postadresse')"
                    >
                    <div>
                        {{ forgotPassword.error }}
                    </div>
                </div>
            </template>
            <template #ok-button>
                <kk-button
                    type="primary"
                    icon
                    class="footer-button"
                    :disabled="isSendingPassword"
                    @click="doForgotPassword()"
                >
                    {{ trans('shared.Send') }}
                </kk-button>
            </template>
        </kk-modal>
        <kk-modal v-if="forgot2FA.showingPrompt" @cancel="closeForgot2FAPrompt()" @ok="doLoginWithResetCode()">
            <template #title>
                {{ trans("login.Nullstill tofaktorautentisering") }}
            </template>
            <template #content>
                <div class="body">
                    <div class="bold textCenter">
                        {{ trans("login.Har du glemt din tofaktorautentisering?") }}
                        {{ trans('login.Her får du muligheten til å deaktivere den ved bruk av en nullstillings-kode') }}
                    </div>
                    <div class="textCenter">
                        {{ trans("login.Om du ikke har nullstillings-koden din må du ta kontakt med support via. ikonet nede til høyre") }}
                    </div>
                    <input
                        v-model="forgot2FA.code"
                        type="text"
                        name="code"
                        :placeholder="trans('login.Din nullstillings-kode')"
                    >
                    <div>
                        {{ forgot2FA.message }}
                    </div>
                </div>
            </template>
            <template #ok-button>
                <kk-button
                    type="primary"
                    icon
                    class="footer-button"
                    :disabled="forgot2FA.code.length === 0"
                    @click="doLoginWithResetCode()"
                >
                    {{ trans('shared.OK') }}
                </kk-button>
            </template>
        </kk-modal>
    </div>
</template>

<script>
import Vue from 'vue';
import { trans } from 'vue-components/src/mixin/trans.js';
import { kkModal, kkButton, ks } from 'vue-components';
import { registerAxiosInterceptor } from '../../libs/axios-interceptor.js';
import { setNewCSRF } from '../../libs/csrf-token.js';
import axios from 'axios';

Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.mixin(trans);

export default {
    name: 'login-desktop',
    components: { kkModal, kkButton },
    mixins: [trans],
    data: () => ({
        form: {
            username: '',
            password: '',
            twofactor: '',
            remember: false,
        },
        messages: [],
        show2fa: false,
        forgotPassword: {
            showingPrompt: false,
            email: '',
            error: '',
        },
        forgot2FA: {
            showingPrompt: false,
            code: '',
            message: '',
        },
        isLoading: false,
        sms: false,
        expired: false,
        currentTime: 0,
        timeout: 0,
        isSendingPassword: false,
    }),
    computed: {
        remainingTokenTime() {
            return this.timeout - this.currentTime;
        },
    },
    watch: {},
    created() {
        registerAxiosInterceptor();
    },
    methods: {
        setNewCSRF,
        async doLogin() {
            if (this.isLoading) {
                return;
            }

            if (this.expired) {
                this.form.twofactor = '';
            }

            this.isLoading = true;
            this.expired = false;
            this.messages = [];

            axios.defaults.validateStatus = function() {
                return true;
            };

            const response = await axios.post('ajax/login', this.form);

            if (response.status === 401) {
                if (response.data.twofactor === '__2FA__') {
                    this.show2fa = true;
                    this.isLoading = false;

                    const twofactorError = this.trans('login.Tofaktorautentisering kreves');
                    this.messages.push(twofactorError);

                    if (response.data.twofactor_type === 'SMS') {
                        ks.toast('2fa.Kode sent på sms');

                        this.sms = true;
                        this.timeout = response.data.timeout;

                        this.currentTime = Math.floor(new Date().getTime() / 1000);
                        setInterval(() => {
                            this.currentTime = Math.floor(new Date().getTime() / 1000);

                            if (this.remainingTokenTime <= 0) {
                                this.expired = true;
                            }
                        }, 1000);
                    }

                    return;
                }

                for (const key in response.data) {
                    const value = response.data[key];

                    if (value === null) {
                        continue;
                    }

                    this.messages.push(value);
                }
            }

            if (response.status === 200) {
                if (response.data.intended) {
                    location.href = response.data.intended;
                    this.isLoading = true;

                    return;
                }

                location.href = window.location.origin;
                this.isLoading = true;

                return;
            }

            if (response.status === 500) {
                ks.toast('Noe gikk galt, vennligst kontakt support');
            }

            this.isLoading = false;
        },
        openForgotPasswordPrompt() {
            this.forgotPassword.showingPrompt = true;
        },
        closeForgotPasswordPrompt() {
            this.forgotPassword.showingPrompt = false;
            this.forgotPassword.email = '';
            this.forgotPassword.error = '';
        },
        async doForgotPassword() {
            this.isSendingPassword = true;
            this.forgotPassword.error = this.trans('shared.Sender e-post...');

            const response = await axios.post('/ajax/reset-password', { email: this.forgotPassword.email });
            this.forgotPassword.error = response.data;

            if (Array.isArray(response.data)) {
                this.forgotPassword.error = this.trans('shared.Epost sendt');
            }

            this.forgotPassword.email = '';
            this.isSendingPassword = false;
        },
        openForgot2FAPrompt() {
            this.forgot2FA.showingPrompt = true;
        },
        closeForgot2FAPrompt() {
            this.forgot2FA.showingPrompt = false;
            this.forgot2FA.code = '';
            this.forgot2FA.message = '';
        },
        async doLoginWithResetCode() {
            this.forgot2FA.message = 'Laster...';

            const response = await axios.post('ajax/login', {
                ...this.form,
                recovery_code: this.forgot2FA.code,
            });

            if (response.status === 200) {
                if (response.data.intended) {
                    location.href = response.data.intended;
                    this.closeForgot2FAPrompt();
                    this.isLoading = true;

                    return;
                }

                location.href = window.location.origin;
                this.closeForgot2FAPrompt();
                this.isLoading = true;
            }

            this.forgot2FA.message = response.data.recovery_code;
        },
    },
};
</script>

<style lang="scss">
// remove some styles so we have the entire page..
.content {
    width: 100% !important;

    .articlecontainer {
        visibility: visible !important;
        box-shadow: none !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }
}
</style>

<style lang="scss" scoped>
@import '~sass/_variables.scss';

.login-desktop {
    width: 100%;
    height: 100%;
    color: black;
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;

    .form-container {
        background: url('/design/desktop-login.jpg');
        background-position: 0 30%;
        background-size: cover;

        width: 50vw;
        height: 90%;
        min-width: 1033px;

        margin: 20px 40px;

        grid-row: 1/1;
        grid-column: 1/1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .darkened {
            background: rgba(0, 0, 0, 0.38);
            width: 100%;

            .heading {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h1 {
                    color: white;
                    font-size: 2.5rem;
                    font-weight: 200;
                    border-bottom: 7px solid $blue;
                    padding: 0 30px;
                    text-transform: uppercase;

                    span {
                        padding-left: 10px;
                        color: $blue;
                    }
                }
            }

            .form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                input[type='text'],
                input[type='password'] {
                    font-size: 18px;
                    border-radius: 4px;
                    display: block;
                    width: 470px;
                    padding: 15px;
                    margin-bottom: 35px;
                }

                .twofa {
                    color: white;
                    margin-bottom: 35px;

                    input {
                        margin-bottom: 0px;
                    }
                }

                .bottom {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 470px;

                    .remember {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        margin: 0 auto;

                        label {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            font-size: 18px;
                            line-height: 23px;
                        }

                        input[type='checkbox'] {
                            background: white;
                            width: 20px;
                            height: 20px;
                            border: 1px solid black;
                            border-radius: 4px;
                            appearance: none;
                            cursor: pointer;

                            &:checked {
                                background: url('/design/checkmark.gif') white no-repeat center;
                            }

                            &:hover {
                                background: lightgray;
                            }

                            &:checked:hover {
                                background: url('/design/checkmark.gif') lightgray no-repeat center;
                            }
                        }
                    }

                    button {
                        margin-left: auto;
                        border: 0;
                        font-size: 18px;
                        color: white;
                        background: $blue;
                        padding: 15px 20px;
                        border-radius: 4px;
                        text-transform: uppercase;
                    }

                    button:hover {
                        background: $blueHover;
                    }
                }
            }

            .errors {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                margin-top: 10px;

                color: white;
                background: $red;
                font-size: 18px;

                div {
                    padding: 5px 0;
                }
            }

            a {
                color: white;
                font-size: 18px;
                text-decoration: underline;
                display: block;
                margin-left: auto;
                margin-top: 0;
                margin-bottom: 5px;
                margin-right: 25%;
                width: fit-content;
                cursor: pointer;

                &:first-of-type {
                    margin-top: 15px;
                }

                &:last-of-type {
                    margin-bottom: 20px;
                }

                &:hover {
                    opacity: 0.8;
                    text-decoration: none;
                }
            }
        }
    }

    .kk-modal {
        .body {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.1rem;

            div {
                margin: 10px;
            }

            input[type='email'],
            input[type='text'] {
                width: 90%;
                margin-top: 10px;
                padding: 8px 10px;
                color: black;
                border: 1px solid lightgray;
                border-radius: 3px;
            }

            .textCenter {
                text-align: center;
            }

            .bold {
                font-weight: 700;
            }
        }
    }

    .clickable {
        cursor: pointer;
    }
}
</style>
